<template>
  <div class="flex items-center mt-5 space-x-4">
    <lf-h4>{{ $t("DEALS.LEXIS_NEXIS.KYB.RECORDS") }}</lf-h4>
    <lf-pill v-if="!!total" :color-index="15">{{ total }}</lf-pill>
  </div>
  <div class="space-y-5">
    <toggle-section
      v-for="(record, index) in mappedRecords"
      :key="JSON.stringify(record)"
      data-cy="lexis-nexis-record"
      :is-open="isOpen[`record_${index}`]"
      v-bind:section="`record_${index}`"
      chevron-position="right"
      :has-padding="false"
      @toggle="toggleTable"
    >
      <template #titleSection>
        <lf-h3>
          {{ getCompanyName(record) }}
        </lf-h3>
      </template>
      <template #content>
        <service-data :data="record">
          <template #expansion="{ row }">
            <div v-if="row.data">
              <section-details :data="getData(row.data)" :update="row.update" />
            </div>
          </template>
        </service-data>
      </template>
    </toggle-section>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type {
  CompanyNameInfo,
  Response as LexisNexisResponse
} from "@/models/commercialData/lexisnexis/KybSearch";
import ToggleSection from "@/components/ToggleSection.vue";
import ServiceData from "@/components/deals/dataServices/ServiceData.vue";
import SectionDetails from "@/components/deals/dataServices/kyb/lexisNexisSearch/SectionDetails.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDeals } from "@/hooks/deals";
import type { IServiceDataRow } from "@/models/common";
import { validateString } from "@/helpers/common";
import pick from "lodash/pick";

const props = defineProps({
  data: {
    type: Object as PropType<LexisNexisResponse>,
    required: true
  }
});

const { t } = useI18n();
const { activeDeal } = useDeals();

const isOpen = ref<Record<string, boolean>>(
  props.data?.Records.Record.reduce<Record<string, boolean>>(
    (acc, _, index) => {
      acc[`record_${index}`] = false;
      return acc;
    },
    {}
  )
);

const toggleTable = (key: string) => {
  isOpen.value[key] = !isOpen.value[key];
};

const total = computed(() => props.data?.RecordCount || 0);
const records = computed(() => props.data?.Records.Record || null);
const currentBusinessEin = computed(
  () =>
    activeDeal.value?.business?.piis?.find((pii) => pii.key === "EIN")?.value ||
    ""
);

const mappedRecords = computed(() => {
  if (!records.value) {
    return [];
  }

  const header = {
    title: t("DEALS.CLEAR_KYB.SECTION_NAME"),
    result: t("COMMON.MATCH"),
    counter: false
  };

  return records.value.reduce<IServiceDataRow[][]>((acc, record) => {
    const companyNameInfo = {
      expandable: hasData(record.Best?.CompanyNameInfo),
      title: t("DEALS.LEXIS_NEXIS.KYB.COMPANY_NAME_INFO"),
      data: validateData(record.Best?.CompanyNameInfo),
      counter: false,
      update: [
        {
          field: "Company Name",
          modelToUpdate: {
            model: "application.business",
            field: "business_legal_name"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.business_legal_name || "",
            newValue: record.Best?.CompanyNameInfo?.CompanyName || ""
          }
        }
      ]
    };
    const tinInfo = {
      expandable: hasData(record.Best?.TINInfo),
      title: t("DEALS.LEXIS_NEXIS.KYB.TIN_INFO"),
      data: record.Best?.TINInfo,
      counter: false,
      update: [
        {
          field: "TIN",
          modelToUpdate: {
            model: "workflow.business",
            field: "business_ein"
          },
          modelValues: {
            oldValue: currentBusinessEin.value,
            newValue: (record.Best?.TINInfo?.TIN || "").replace(/-/g, "")
          }
        }
      ]
    };
    const contactInfo = {
      expandable: hasData(record.Best?.ContactInfo),
      title: t("DEALS.LEXIS_NEXIS.KYB.CONTACT_INFO"),
      data: validateData(record.Best?.ContactInfo),
      counter: false,
      update: [
        {
          field: "First",
          modelToUpdate: {
            model: "application.personal_information",
            field: "first_name"
          },
          modelValues: {
            oldValue: activeDeal.value?.personal_information?.first_name || "",
            newValue: record.Best?.ContactInfo?.Name?.First || ""
          }
        },
        {
          field: "Last",
          modelToUpdate: {
            model: "application.personal_information",
            field: "last_name"
          },
          modelValues: {
            oldValue: activeDeal.value?.personal_information?.last_name || "",
            newValue: record.Best?.ContactInfo?.Name?.Last || ""
          }
        }
      ]
    };
    const businessIds = {
      expandable: hasData(record.BusinessIds),
      title: t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_IDS"),
      data: validateData(record.BusinessIds),
      counter: false,
      update: [
        {
          field: "Sele ID",
          modelToUpdate: {
            model: "application.business",
            field: "sele_id"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.sele_id?.toString() || "",
            newValue: record.BusinessIds?.SeleID.toString() || ""
          }
        }
      ]
    };
    const emailInfo = {
      expandable: hasData(record.Best?.EmailInfo),
      title: t("DEALS.LEXIS_NEXIS.KYB.EMAIL_INFO"),
      data: validateData(record.Best?.EmailInfo),
      counter: false
    };
    const addressFromDate = {
      expandable: hasData(record.Best?.AddressFromDate),
      title: t("DEALS.LEXIS_NEXIS.KYB.ADDRESS_FROM_DATE"),
      data: validateData(record.Best?.AddressFromDate),
      counter: false
    };
    const addressToDate = {
      expandable: hasData(record.Best?.AddressToDate),
      title: t("DEALS.LEXIS_NEXIS.KYB.ADDRESS_TO_DATE"),
      data: validateData(record.Best?.AddressToDate),
      counter: false
    };
    const currentAddress = pick(record.Best?.AddressInfo?.Address, [
      "StreetNumber",
      "StreetName",
      "StreetSuffix"
    ]);
    const address = {
      expandable: hasData(record.Best?.AddressInfo?.Address),
      title: t("COMMON.ADDRESS"),
      data: validateData(record.Best?.AddressInfo?.Address),
      counter: false,
      update: [
        {
          field: "City",
          modelToUpdate: {
            model: "application.address",
            field: "city"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.address?.city || "",
            newValue: record.Best?.AddressInfo?.Address?.City || ""
          }
        },
        {
          field: "Zip 5",
          modelToUpdate: {
            model: "application.address",
            field: "zip"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.address?.zip || "",
            newValue: record.Best?.AddressInfo?.Address?.Zip5 || ""
          }
        },
        {
          field: "State",
          modelToUpdate: {
            model: "application.address",
            field: "state"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.address?.state || "",
            newValue: record.Best?.AddressInfo?.Address?.State || ""
          }
        },
        {
          field: "Street Name",
          modelToUpdate: {
            model: "application.address",
            field: "address_line"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.address?.address_line || "",
            newValue: Object.values(currentAddress).join(" ")
          }
        }
      ]
    };
    const activeEda = {
      tooltip: t("DEALS.LEXIS_NEXIS.KYB.ELECTRONIC_DIRECTORY_ASSISTANCE"),
      title: t("DEALS.LEXIS_NEXIS.KYB.ACTIVE_EDA"),
      result: validateBoolean(record.Best?.ActiveEDA),
      counter: false
    };
    const phoneInfo = {
      expandable: hasData(record.Best?.PhoneInfo),
      title: t("DEALS.LEXIS_NEXIS.KYB.PHONE_INFO"),
      data: validateData(record.Best?.PhoneInfo),
      counter: false,
      update: [
        {
          field: "Phone 10",
          modelToUpdate: {
            model: "application.business",
            field: "telephones"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.telephones?.[0] || "",
            newValue: record.Best?.PhoneInfo?.Phone10 || ""
          }
        }
      ]
    };
    const isPhoneDisconnected = {
      title: t("DEALS.LEXIS_NEXIS.KYB.IS_PHONE_DISCONNECTED"),
      result: validateBoolean(record.Best?.Disconnected),
      counter: false
    };
    const urlInfo = {
      expandable: hasData(record.Best?.URLInfo),
      title: t("DEALS.LEXIS_NEXIS.KYB.URL_INFO"),
      data: validateData(record.Best?.URLInfo),
      counter: false,
      update: [
        {
          field: "URL",
          modelToUpdate: {
            model: "application.business",
            field: "website_address"
          },
          modelValues: {
            oldValue: activeDeal.value?.business?.website_address || "",
            newValue: record.Best?.URLInfo?.URL || ""
          }
        }
      ]
    };
    const busiessCreditIndicator = {
      title: t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_CREDIT_INDICATOR"),
      result: validateString(String(record.BusinessCreditIndicator)),
      counter: false
    };
    const isParentCompanyIndicator = {
      title: t("DEALS.LEXIS_NEXIS.KYB.IS_PARENT_COMPANY_INDICATOR"),
      result: validateBoolean(record.Best?.ParentCompanyIndicator),
      counter: false
    };
    const isActive = {
      title: t("DEALS.LEXIS_NEXIS.KYB.IS_ACTIVE"),
      result: validateBoolean(record.Best?.TINInfo?.TINInfoMatch),
      counter: false
    };
    const alsoFound = {
      expandable: hasData(record.Best?.AlsoFound),
      title: t("DEALS.LEXIS_NEXIS.KYB.ALSO_FOUND"),
      data: validateData(record.Best?.AlsoFound),
      counter: false
    };
    const isDefunct = {
      title: t("DEALS.LEXIS_NEXIS.KYB.IS_DEFUNCT"),
      result: validateBoolean(record.Best?.IsDefunct),
      counter: false
    };
    return [
      ...acc,
      [
        header,
        companyNameInfo,
        businessIds,
        tinInfo,
        contactInfo,
        emailInfo,
        addressFromDate,
        addressToDate,
        address,
        activeEda,
        phoneInfo,
        isPhoneDisconnected,
        urlInfo,
        busiessCreditIndicator,
        isParentCompanyIndicator,
        isActive,
        alsoFound,
        isDefunct
      ]
    ];
  }, []);
});

function validateData<T>(data: T | undefined): T | "-" {
  return data ? data : "-";
}

const validateBoolean = (value: boolean | undefined) => {
  return value ?? "-";
};

function hasData<T>(data: T | "-") {
  return data !== "-";
}

function getData<T>(data: T): IServiceDataRow[] {
  return Object.entries(data || {}).flatMap(([key, value]) => {
    // necessary to handle nested object (eg. ContactInfo.Name.First)
    if (typeof value === "object") {
      return getData(value);
    }
    return {
      title: key,
      result: (value ?? "-") as string
    };
  });
}

const getCompanyName = (record: IServiceDataRow[]) => {
  return (record?.[1]?.data as CompanyNameInfo)?.CompanyName || "-";
};
</script>
